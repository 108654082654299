@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');


.slide-entering,
.slide-exiting,
.slide-exited {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.slide-entered {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
/* @font-face{font-family:century gothic;font-style:normal;font-weight:400;src:local('Century Gothic'),url(https://fonts.cdnfonts.com/s/18111/GOTHIC.woff) format('woff')}@font-face{font-family:century gothic;font-style:italic;font-weight:400;src:local('Century Gothic'),url(https://fonts.cdnfonts.com/s/18111/GOTHICI.woff) format('woff')}@font-face{font-family:century gothic;font-style:normal;font-weight:700;src:local('Century Gothic'),url(https://fonts.cdnfonts.com/s/18111/GOTHICB.woff) format('woff')}@font-face{font-family:century gothic;font-style:italic;font-weight:700;src:local('Century Gothic'),url(https://fonts.cdnfonts.com/s/18111/GOTHICBI.woff) format('woff')} */



@font-face {
  font-family: 'century gothic';
  font-style: normal;
  font-weight: 400;
  src: local('Century Gothic'), url("./assets/fonts/GOTHIC.TTF") format('truetype');
}

@font-face {
  font-family: 'century gothic';
  font-style: Bold;
  font-weight: 700;
  src: local('Century Gothic Bold'), url("./assets/fonts/gothicb.ttf") format('truetype');
}