.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-entering,
.slide-exiting,
.slide-exited {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.slide-entered {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

@font-face{
font-family:century gothic;
font-style:normal;
font-weight:400;
src:local('Century Gothic'),
url(https://fonts.cdnfonts.com/s/18111/GOTHIC.woff)
 format('woff')}
 @font-face{font-family:century gothic;
 font-style:italic;
 font-weight:400;
 src:local('Century Gothic'),
 url(https://fonts.cdnfonts.com/s/18111/GOTHICI.woff)
  format('woff')}
  @font-face{font-family:century gothic;
  font-style:normal;
  font-weight:700;
  src:local('Century Gothic'),
  url(https://fonts.cdnfonts.com/s/18111/GOTHICB.woff) 
  format('woff')}
  @font-face{font-family:century gothic;
  font-style:italic;
  font-weight:700;
  src:local('Century Gothic'),
  url(https://fonts.cdnfonts.com/s/18111/GOTHICBI.woff) 
  format('woff')}